import store from '@/store'

export function deliveryDataRowTransfer (data) {
  return {
    ...data,
    priority: store.getters['scheduleSetting/priorityOptions'][data.priority - 1].label,
    complete: data.withinScheduleTime ? this.$t('schedule.simulation.table.completed') : this.$t('schedule.simulation.table.uncompleted'),
    cycleTime: Number.parseFloat(data.cycleTime).toFixed(1)
  }
}
