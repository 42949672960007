<template>
  <el-tabs
    v-model="innerActiveTab"
    class="page__tabs schedule-tab"
    type="card"
  >
    <el-tab-pane
      v-for="tab in tabs"
      :key="tab.type"
      :label="tab.name"
      :name="tab.type"
    />
    <slot name="right" />
  </el-tabs>
</template>

<script>
export default {
  name: 'ScheduleTab',
  props: {
    activeTab: {
      type: String,
      default: ''
    },
    tabs: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    innerActiveTab: {
      get () {
        return this.activeTab
      },
      set (value) {
        this.$emit('switch', value)
      }
    }
  }
}
</script>
