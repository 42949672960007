<template>
  <div class="job-status-legend">
    <span
      v-for="status in jobStates"
      :key="status"
      :style="{ color: jobColorVar[status.toLowerCase()] }"
      :class="{ 'is-hollow': status === JOB_STATUS.UNSCHEDULED }"
      class="legend"
    >
      {{ $t(`schedule.rolling.${snakeToCamel(status)}`) }}
    </span>
  </div>
</template>

<script>
import { JOB_STATUS } from '@/schedule/utils/enum'
import { snakeToCamel } from '@/schedule/utils/utils'
import jobColorVar from '@/schedule/styles/common/_variables.scss'

export default {
  name: 'JobStatusLegend',
  props: {
    jobStates: {
      type: Array,
      default: () => Object.values(JOB_STATUS)
    }
  },
  data () {
    return {
      JOB_STATUS,
      jobColorVar
    }
  },
  methods: {
    snakeToCamel
  }
}
</script>

<style lang="scss" scoped>
@import '@/schedule/styles/components/_job.scss';

.job-status-legend {
  align-items: center;
  display: flex;
  font-weight: 400;
  gap: 8px;

  .legend {
    @include job-state-dot;

    font-size: 14px;
    padding-left: 12px;
    position: relative;
  }
}
</style>
