<template>
  <spinner v-if="isLoading" />
  <pagination-table
    v-else
    :pagination-info="pagination"
    :dataset="tableData"
    fixed-index
    height="100%"
    @change-page="updatePage"
  />
</template>

<script>
import PaginationTable from '@/schedule/components/table/PaginationTable'

export default {
  name: 'ScheduleDataTable',
  components: {
    PaginationTable
  },
  props: {
    remoteMethod: {
      type: Function,
      default: null,
      required: true
    },
    params: {
      type: Object,
      default: () => {}
    },
    tableHeaderList: {
      type: Array,
      default: () => []
    },
    restrictions: {
      type: Object,
      default: () => {}
    },
    dataRowTransfer: {
      type: Function,
      default: data => data,
      required: false
    }
  },
  watch: {
    restrictions () {
      this.fetchData()
    }
  },
  data () {
    return {
      isLoading: true,
      tableData: {},
      pagination: {
        currentPage: 0,
        totalPage: 0
      }
    }
  },
  computed: {
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData (page = 0, size = 20) {
      this.isLoading = true
      this.remoteMethod({ ...this.params, page, size, ...this.restrictions })
        .then(res => {
          this.pagination = res.pagination
          this.tableData = {
            columns: { titles: this.tableHeaderList },
            data: res.data.map(data => this.dataRowTransfer(data)) || [],
            index: [...Array(res.data ? res.data.length : [])].map((x, i) => i)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    updatePage (page) {
      this.fetchData(page - 1, 20)
    }
  }
}
</script>
