<template>
  <div class="jobs__filter">
    <!-- 工單狀態 -->
    <div class="filter__title">
      {{ $t('schedule.base.jobState') }}
    </div>
    <schedule-select
      v-model="selectedJobStatus"
      :options="innerJobStatesOptions"
      class="filter__job-status-selector"
      @input="searchJobData"
    />
    <!-- 工單交付日 -->
    <span class="filter__title"> {{ $t('schedule.simulation.jobsDueDate') }} </span>
    <schedule-date-picker
      v-model="period"
      :clearable="true"
      type="daterange"
      format="yyyy/MM/dd"
      value-format="yyyy/MM/dd"
      class="filter__date-picker"
    />
    <div class="search">
      <!-- 當前計畫頁面 使用 ticket filter 提供訂單 / 工單選項預覽，以及 fuzzy search 功能 -->
      <remote-search-select
        v-if="isCurrentSimulationPage"
        v-model="keyword"
        class="filter__search"
        :placeholder="$t('schedule.simulation.searchJobOrOrder')"
        :search-method="keywordSearch"
        @change="keyword = $event"
      />
      <!-- 其餘頁面（選擇工單、模擬結果頁面）使用一般 input -->
      <schedule-input
        v-else
        v-model="keyword"
        :placeholder="$t('schedule.simulation.searchJobs')"
        type="text"
        class="filter__search"
      >
        <i
          slot="prepend"
          class="el-icon-search filter__search--icon"
        />
      </schedule-input>
    </div>
    <schedule-button
      size="m"
      type="outline"
      @click="searchJobData"
    >
      {{ $t('schedule.button.send') }}
    </schedule-button>
  </div>
</template>

<script>
import { keywordSearch } from '@/schedule/API/Plan'
import RemoteSearchSelect from './RemoteSearchSelect'
import { JOB_STATUS } from '@/schedule/utils/enum'
import { snakeToCamel } from '@/schedule/utils/utils'
import { mapState } from 'vuex'

export default {
  name: 'JobsFilter',
  components: {
    RemoteSearchSelect
  },
  props: {
    jobStatesOptions: {
      type: Array,
      default: () => Object.values(JOB_STATUS)
    }
  },
  data () {
    return {
      selectedJobStatus: null,
      period: [],
      keyword: ''
    }
  },
  computed: {
    ...mapState('scheduleSetting', ['globalJobStatusRestriction']),
    innerJobStatesOptions () {
      const all = { value: null, label: this.$t('schedule.simulation.allJobStatus') }
      const status = this.jobStatesOptions.map(status => ({
        value: status,
        label: this.$t(`schedule.rolling.${snakeToCamel(status)}`)
      }))
      return [all, ...status]
    },
    innerRestrictions () {
      const restrictions = {}

      switch (this.selectedJobStatus) {
        case JOB_STATUS.UNSCHEDULED:
          restrictions.scheduled = false
          break
        case JOB_STATUS.SCHEDULED:
          restrictions.scheduled = true
          restrictions.delayed = false
          restrictions.withinScheduleTime = true
          break
        case JOB_STATUS.OVERDUE:
          restrictions.delayed = false
          restrictions.withinScheduleTime = false
          break
        case JOB_STATUS.DELAY:
          restrictions.delayed = true
          restrictions.withinScheduleTime = true
          break
        case JOB_STATUS.OVERDUE_DELAY:
          restrictions.delayed = true
          restrictions.withinScheduleTime = false
      }
      return {
        keyword: this.keyword,
        deadlineStartDate: this.period && this.period.length > 0 ? this.period[0] : null,
        deadlineEndDate: this.period && this.period.length > 0 ? this.period[1] : null,
        ...restrictions
      }
    },
    isCurrentSimulationPage () {
      return this.$route.name === 'CurrentSimulation'
    }
  },
  watch: {
    globalJobStatusRestriction: {
      immediate: true,
      handler (jobStatus) {
        if (jobStatus) {
          this.selectedJobStatus = jobStatus.toUpperCase()
          // 還原狀態
          this.$store.commit('scheduleSetting/setGlobalJobStatusRestriction', null)
          this.searchJobData()
        }
      }
    }
  },
  methods: {
    searchJobData () {
      this.$emit('submit', this.innerRestrictions)
    },
    snakeToCamel,
    keywordSearch
  }
}
</script>

<style lang="scss" scoped>
.jobs__filter {
  align-items: center;
  display: flex;

  .filter {
    &__title {
      font-size: 14px;
      line-height: 18px;
      margin-right: 8px;
    }

    &__job-status-selector {
      display: flex;
      margin-right: 16px;
      padding-bottom: 0;
      width: 130px;
    }

    &__date-picker {
      background: rgba(50, 58, 58, 0.95);
      border-bottom: none;
      border-radius: 6px;
      height: 32px;
      margin-right: 17px;

      ::v-deep .el-date-editor--daterange {
        align-items: baseline;
        display: flex;
        width: 100%;
      }

      ::v-deep .el-range-separator {
        display: inline-block;
      }

      ::v-deep .el-range-input {
        width: 90px;

        &:last-of-type {
          margin-left: 5px;
        }
      }

      ::v-deep .el-range-separator {
        color: #fff;
        font-size: 12px;
        padding: 0;
      }

      ::v-deep .el-icon-date {
        color: #fff;
      }
    }

    &__search {
      background: rgba(50, 58, 58, 0.95);
      border-radius: 6px;
      display: flex;
      margin-right: 8px;
      padding: 0 6px;

      ::v-deep .el-input__inner {
        border: 0;
      }

      &--icon {
        margin-right: 4px;
      }
    }
  }
}
</style>
